<template>
  <v-menu offset-y :close-on-content-click="false" v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-icon small v-bind="attrs"v-on="on">mdi-filter</v-icon>
      <span color="black"><slot name="default" ></slot></span>
    </template>
    
    <div class="pl-5 pr-5 pt-5 pb-3 search-container">

      <!-- String Type Columns -->
      <div v-if="type == 'String'">
        <v-select
          :items="match_items"
          label="Match Type"
          outlined
          dense
          v-model="selected_rules.match_type"
        ></v-select>
        
        <v-select
          :items="rule_items"
          label="Filter Rule"
          dense
          v-model="selected_rules.rule_type"
        ></v-select>
        <v-text-field
          v-model="selected_rules.value"
          label="Search Text"
          required
          dense
        ></v-text-field>
      </div>

      <!-- Date Type Columns -->
      <div v-if="type == 'Date'">
        <v-select
          :items="match_items"
          label="Match Type"
          outlined
          dense
          v-model="selected_rules.match_type"
        ></v-select>
        
        <v-select
          :items="date_rule_items"
          label="Filter Rule"
          dense
          v-model="selected_rules.rule_type"
        ></v-select>
        <v-menu
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selected_rules.value"
              label="Search Date"
              v-bind="attrs"
              v-on="on"
              
            ></v-text-field>
          </template>
          <v-date-picker  
            v-model="selected_rules.value" 
            @input="date_menu = false"
          >   
         </v-date-picker>
        </v-menu>
      </div>

      <!-- Dropdown Type Columns -->
      <div v-if="type == 'Dropdown'">
        <v-select
          :items="match_items"
          label="Match Type"
          outlined
          dense
          v-model="selected_rules.match_type"
        ></v-select>
        
        <v-select
          :items="rule_items"
          label="Filter Rule"
          dense
          v-model="selected_rules.rule_type"
        ></v-select>
        <v-autocomplete
          :loading="loading_dropdown"
          :items="dropdown_items"
          item-text="name"
          item-value="name"
          v-model="selected_rules.value"
          label="Choose Value"
          required
          dense
          :multiple="multiDropdown"
        ></v-autocomplete>
      </div>

      <v-row>
        <v-col cols="12" md="6" class="text-left">
          <v-btn color="primary" small class="mt-2" @click="applyFilters">Apply</v-btn>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn color="primary" text small class="mt-2" @click="menu = false">Close</v-btn>
        </v-col>
      </v-row>
    </div>

  </v-menu>
</template>

<script>
export default{
  props:{
    type : {
      type    : String,
      default : "String" 
    },
    colName : {
      type : String,
      default : ''
    },
    fetchUrl : {
      type    : String,
      default : ''
    },
    multiDropdown : {
      type    : Boolean,
      default : false
    },
    tableName : {
      type    : String,
      default : ''
    }
  },
  data : ()=>({
    menu      : false,
    date_menu : false,
    selected_rules : {
      match_type : '',
      rule_type  : '',
      value      : '',
      col_name   : '',
    },
    match_items      : ['Match All', 'Match Any'],
    rule_items       : ['Contains', 'Not Contains', 'Start With', 'Ends With', 'Equals', 'Not Equals'],
    date_rule_items  : ['Date is', 'Date is not', 'Date is before', 'Date is after'],
    dropdown_items   : [],
    loading_dropdown : false
  }),
  mounted(){
    if (this.type == 'Dropdown'){
      this.getDropdownValues()
    }
  },
  methods:{
    /**
    * @Description
    * 
    * 1.  This method is used to apply the filters for the datatable from table column headers design
    * 2.  NOTE : THIS COMPONENET IS SHARED B/W ALL DATATABLES
    * 3.  It first gets the column name for which filter is applied
    * 4.  Then gets the rules applied for that filter and gets the filters from already saved in store (Vuex)
    * 5.  It check if the same table rules are already saves, and then same columns rules are already saved
    * 6.  Then rewerite the earlier applied rules, 
    * 6.1 if table rules exists but this column rules does not exists, then it add new rules in vue
    * 7.  if that table filters are not applied, it pushes the new filter all togather for the the new table
    * 8.  Then it emits the 'filter' to parent with applied filter to get newly applied filter
    * 
    * @params none
    * @return void
    **/
    applyFilters(){
      this.selected_rules.col_name = this.colName
      let new_filters_to_apply     = { table : this.tableName.toLowerCase(), filters : [this.selected_rules] } 
      let table_filters            = this.$store.state.TABLE_FILTERS

      if(table_filters.length > 0){

        let table_exists = table_filters.find(d=>d.table == this.tableName.toLowerCase())

        if(table_exists && Object.keys(table_exists).length > 0){
          let tbl_index  = table_filters.indexOf(table_exists)
          let col_exists = table_exists.filters.find(d=>d.col_name == this.colName)

          if(col_exists && Object.keys(col_exists).length > 0){
            let col_index = table_exists.filters.indexOf(col_exists)
            this.$store.commit('rewriteExistingFilter',[tbl_index,col_index,this.selected_rules])//rewrite exisitng rule of same col
          }else{
            this.$store.commit('appendNewColFilter',[tbl_index,this.selected_rules])//new rule for existing table, but new col,
          }
        }else{
          this.$store.commit('setTableFilters', new_filters_to_apply)
        }
      }else{
        this.$store.commit('setTableFilters', new_filters_to_apply)//total new if no table even selected
      }

      this.$emit('filter', this.selected_rules)
      this.menu = false
    },
    /**
    * @Description
    * 
    * 1. This method is used to fetch the dropdown values from backend
    * 2. It gets the dynamic backend url and sends HTTP get request to backend to get values
    * 3. The upon successfull receiving it assigns to the dropdown_items to display in dropdown list
    * 
    * @params none
    * @return void 
    **/
    getDropdownValues(){

      if (this.colName == 'violation_class' || this.colName == 'violation_responsibility'){
        this.dropdown_items = this.getViolationsList(this.colName)
        return
      }

      if (!this.fetchUrl) { return }

      this.loading_dropdown = true
      this.$store.dispatch('HTTP_GET_REQUEST',this.fetchUrl).then(response=>{
        if(response.data){
          this.dropdown_items = response.data.map(d=>{
            return {
              id   : d.id || d.ID,
              name : d['Full Name'] || d.name || d.Name || d.company_name
            }
          })
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.loading_dropdown = false
      })
    },
    /**
    * This method is used to get violations dropdowns(class & responsiblity) data as it's not at the backend. 
    **/
    getViolationsList(col){
      switch(col){ 

        case 'violation_class': 
          return [
            {id : 'class_1', name : 'class_1'},
            {id : 'class_2', name : 'class_2'},
            {id : 'class_3', name : 'class_3'}
          ]
        case 'violation_responsibility':
          return [
            {id: 'our',             name : 'our'},
            {id: 'customer',        name : "customer"},
            {id: 'both' ,           name : "both"},
            {id: 'wrong_violation', name : "wrong_violation"}
          ]
      }

    },

  }
};
</script>

<style>
  .search-container{
    background-color:white;
    width:250px;
  }
</style>