<template>
  <div>  

    <div class="dropzone" @dragover.prevent @dragleave="dragleave" @dragenter="dragenter" @drop="drop" ref="dropzone">
      <slot v-if="!disableLabel">
        <h1 class="black--text">{{ label || 'Upload Box' }}</h1>
      </slot>
      <v-btn x-large @click="$refs.filebtn.click()">
        <v-icon left dark>mdi-open-in-app</v-icon> Browse
      </v-btn>
      <p v-if="!disableHint">*Browse or Drag & Drop files here</p>
      <div class="input-container">
        <v-input
          v-for="file in files"
          :key="file.name"
          append-icon="mdi-close"
          @click:append="remove(file)"
          :prepend-icon="'mdi-'+getFileIcon(file.name)"
        >
          {{ file.name }}
        </v-input>
      </div>
      <input
        ref="filebtn"
        class="filebtn"
        type="file"
        :multiple="multiple"
        :accept="validatedAccept && [...validatedAccept.extensions, ...validatedAccept.mimetypes].join(',')"
        @input="upload"
      />
    </div>

    <hr v-if="showDocType">
    <div class="mt-3" v-if="showDocType">
      <v-autocomplete
        v-model="docType"
        :items="docTypesList"
        outlined
        dense
        label="Document Type"
        :loading="loadingDocTypes"
        item-text="name"
        item-value="ID"
      ></v-autocomplete>
    </div>
    <hr>
    <div class="mt-3 mb-3 text-center">
      <v-btn color="primary" class="pl-10 pr-10" 
        text 
        @click="uploadFiles" 
        :loading="loading" 
        :disabled="disableUploadBtn"
        v-show="showUploadBtn"
      >
        <v-icon left dark>mdi-cloud-upload</v-icon> Upload Files
      </v-btn>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    accept: {
      type: String,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
    loading : {
      type : Boolean,
      default : false
    },
    disableLabel: {
      type: Boolean,
      required: false,
    },
    disableHint: {
      type: Boolean,
      required: false,
    },
    reset : {
      type : Boolean,
      default : false
    },
    showDocType : {
      type    : Boolean,
      default : true
    },
    showUploadBtn : {
      type    : Boolean,
      default : true
    },
    filterEstimationsTypes : {
      type    : Boolean,
      default : false
    }
  },
  data() {
    return {
      hoverCounter    : 0,
      hoveringContent : null,
      matchAnything   : /.*/,
      files           : [],
      docType         : null,
      docTypesList    : [],
      loadingDocTypes : false
    }
  },
  computed: {
    /**
    * @Description
    * 
    * 1. This computed prop is used to set upload button disabled
    * 2. It checks if doc types are dhows then it disable on doc type as required otherwise without doc type
    * 
    **/
    disableUploadBtn(){
      let disable = true
      if (this.showDocType) {
        disable =  this.loading || this.docType == null || this.files.length <= 0
      }else{
        disable = this.loading || this.files.length <= 0
      }

      if (!disable && !this.showUploadBtn){
        this.uploadFiles()
      }

      return disable
    },
    filebtn: {
      cache: false,
      get() {
        return this.$refs.filebtn
      },
    },
    dropzone: {
      cache: false,
      get() {
        return this.$refs.dropzone
      },
    },
    /**
    * @Description
    * 
    * 1. This prop is used to validate the files types
    * 2. IF checks the files are of only allowed types
    * 
    **/
    validTypes() {
      if (this.validatedAccept) {
        return {
          extensions: this.validatedAccept.extensions
          .map(ext => ext.replace(/(\W)/g, '\\$1'))
          .map(rgxstr => new RegExp(`${rgxstr}$`, 'i')),

          mimetypes: this.validatedAccept.mimetypes
          .map(mt => mt.replace(/([-+/])/g, '\\$1'))
          .map(mt => mt.replace(/\*/g, '(?:[A-Za-z0-9\\-\\+]*)*'))
          .map(rgxstr => new RegExp(`^${rgxstr}$`)),
        }
      } else {
        return {
          extensions : [this.matchAnything],
          mimetypes  : [this.matchAnything],
        }
      }
    },
    /**
    * @Description 
    * 
    * 1. This prop is used to validate that files are of only accepted types
    * 
    **/
    validatedAccept() {
      if (this.accept) {
          return {
            extensions : this.accept.split(',').filter(type => type.match(/^\.(?!.*\/)/)),
            mimetypes  : this.accept
            .split(',')
            .filter(type => type.match(/^(?:(?:[A-Za-z0-9\-+]*)|\*)\/(?:(?:[A-Za-z0-9\-+.]*)|\*)$/)),
          }
      } else {
          return null
      }
    },
  },
  watch: {
    reset(val){
      if (val == true) {
        this.files   = []
        this.docType = null
      }
    },
    multiple(val) {
      if (!val) {
        this.files.splice(0, this.files.length - 1)
      }
    },
    /**
    * @Description
    * 
    * 1. This watcher is used to set the hover backdrop when files are draged & droped
    **/
    hoveringContent(val) {
      if (val) {
        if (this.accept && this.accept.length && this.validTypes.extensions.length === 0) {
          let shouldDim = false
          for (let i = 0; i < val.length; i++) {
            if (
              this.validTypes.mimetypes.reduce((prev, regex) => prev || !!val[i].type.match(regex))
            ) {
              shouldDim = true
              break
            }
          }
          if (shouldDim) {
            this.dropzone.style.backgroundColor = 'rgba(0, 0, 0, 0.25)'
          }
        } else {
          let shouldDim = false
          for (let i = 0; i < val.length; i++) {
            if (val[i].kind === 'file') {
              shouldDim = true
              break
            }
          }
          if (shouldDim) {
            this.dropzone.style.backgroundColor = 'rgba(0, 0, 0, 0.25)'
          }
        }
      } else {
        this.dropzone.style.backgroundColor = ''
      }
    },
    /**
    * @Description
    * 
    * 1. This watcher is used to set the hover counter
    **/
    hoverCounter(val) {
      if (val === 0) {
        this.hoveringContent = null
      }
    },
  },
  mounted(){
    if(this.showDocType)
      this.getDocumentTypes()
  },
  methods: {
    /**
    * @Description
    * 
    * 1. This method is used to get the document types list
    * 2. It sends the request to backend and gets the doc types and assign to doc types dropdown
    * 
    * @params none
    * @return void
    **/
    getDocumentTypes(){
      this.loadingDocTypes = true
      this.$store.dispatch('HTTP_GET_REQUEST',`document-type`).then(response=>{
        if(response.data){
          this.docTypesList = response.data
          if (this.filterEstimationsTypes) {
            this.docTypesList = response.data.filter(d=>d.is_estimation_type == 1)
          }
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.loadingDocTypes = false
      })
    },
    /**
    * 1. This method is used to orgnize the files when Browsed
    * 2. After browsing the files it arranges the all files and put them in the files array to show the list of browsed files
    * 
    * @param none
    * @return void
    **/
    upload() {
      const files = this.filebtn.files ?? []
      for (let i = 0; i < files.length; i++) {
        if (!this.multiple) {
          this.files.splice(0, this.files.length)
        }
        const shouldPush =
          this.validTypes.extensions.reduce((prev, regex) => prev || !!files[i].name.match(regex), false) ||
          this.validTypes.mimetypes.reduce((prev, regex) => prev || !!files[i].type.match(regex), false)
        if (shouldPush) {
          this.files.push(files[i])
        }
      }
      this.filebtn.value = ''
    },
    /**
    * @Description
    * 
    * 1. This method is used to get the files upon drop
    * 2. It shows the hover content and backdrop while enter in dropzone
    * 
    * @param e { JS drag event }
    * @return void
    **/
    dragenter(e) {
      this.hoveringContent = e.dataTransfer.items
      this.hoverCounter++
    },
    dragleave(e) {
      this.hoverCounter--
    },
    /**
    * @Description
    * 
    * 1. This method is used to handle the drop event
    * 2. When mutiple or single file is droped then it tranfer all the files and performs all checks
    * 3. If files are of valid and allowed accepted types then it adds the files in files list
    * 4. If any error occured it rejected all the files and removes all files from files array
    * 
    * @params e { JS Drop Event }
    * @return void
    **/
    drop(e) {
      e.preventDefault()
      this.hoverCounter = 0
      if (e.dataTransfer.items) {
        const rejected = []
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (e.dataTransfer.items[i].kind === 'file') {
            if (e.dataTransfer.items[i].webkitGetAsEntry) {
              const entry = e.dataTransfer.items[i].webkitGetAsEntry()
              if (entry.isDirectory) {
                rejected.push(entry.name)
                continue
              }
            }
            const file = e.dataTransfer.items[i].getAsFile()
            if (file) {
              const shouldPush =
                this.validTypes.extensions.reduce((prev, regex) => prev || !!file.name.match(regex),false,) ||
                this.validTypes.mimetypes.reduce((prev, regex) => prev || !!file.type.match(regex),false,)
              if (shouldPush) {
                if (this.multiple){
                  // Remove duplicates
                  this.files
                  .filter(currFile => currFile.name === file.name)
                  .forEach(fileToRemove => this.files.splice(this.files.indexOf(fileToRemove), 1))
                } else {
                  // Remove all
                  this.files.splice(0, this.files.length)
                }
                this.files.push(file)
              } else {
                rejected.push(file)
                continue
              }
            } else {
              continue
            }
          }
        }
        // Emit rejected files
        if (rejected.length) {
          this.$emit('rejectedFiles', rejected)
        }
      }
    },
    /**
    * @Description
    * 
    * 1. This method is used to remove the file(s) from the list
    * 2. if get the file ad remove from files array
    * 
    * @param file { HTML file }
    * @rerturn void
    **/
    remove(file) {
      const arr = this.files
      arr.splice(arr.indexOf(file), 1)
      this.$emit('update', null)
    },
    /**
    * @Description
    * 
    * 1. This method is called when upload button is clicked
    * 2. It first sets the document type id for each file and 
    * 3. then It emits the all files to parent component to use it further
    * 
    * @params none
    * @return void
    **/
    uploadFiles(){
      this.files.forEach((f,i) => {
        f.document_type_id = this.docType
      })
      this.$emit('upload',this.files)
    }
  },
 
};
</script>

<style scoped>
h1{
  font-size   : 1.5em;
  font-weight : 400;
  font-family : Roboto, sans-serif;
  color       : hsla(0, 0%, 100%, 0.7);
}
p{
  margin      : 0;
  font-size   : 0.85em;
  font-weight : 100;
}
.dropzone{
  display         : flex;
  flex-flow       : column nowrap;
  justify-content : center;
  align-items     : center;
  padding         : 20px;
  border          : 2px dashed hsla(0, 0%, 100%, 0.7);
  border-radius   : 20px;
  overflow        : hidden;
  transition      : background-color 0.2s;
}
div.input-container {
  min-width: 50%;
}
.v-input ::v-deep div.v-input__control div.v-input__slot { 
  margin-top    : 4px;
  margin-bottom : 0 !important;
}
.v-input ::v-deep div.v-input__control div.v-input__slot div.v-messages {
  display: none;
}
input.filebtn {
  display: none;
}
</style>