import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../plugins/axios'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    DARK_MODE             : false, 
    USER_LOGGED_IN        : false,
    USER_DATA             : null,
    CUSTOM_REPORT_COLS    : [],
    USER_MATERIAL_FILTERS : {},
    HOME_CATEGORY_DRANGE  : {}, 
    TABLE_FILTERS         : [],
    SYSTEM_CONFIGS        : [],
    JOB_WIZARD : {
      job        : {},
      contractor : {},
      contacts   : [],
      documents  : []
    },
    ANY_FORM_STATE_CHANGED : false
  },
  getters: {

  },
  mutations: {
    setThemeState(state,data){
      state.DARK_MODE = data
    },
    setLoggedinState(state,data){
      state.USER_LOGGED_IN = data
    },
    setUserData(state,data){
      state.USER_DATA = data
    },
    setAuthToken(state,data){
      state.USER_DATA.authToken = data
    },
    setJobWizardInfo(state,data){
      state.JOB_WIZARD.job = data
    },
    setJobWizardContractor(state,data){
      state.JOB_WIZARD.contractor = data
    },
    setJobWizardContacts(state,data){
      state.JOB_WIZARD.contacts = data
    },
    setJobWizardDocs(state,data){
      state.JOB_WIZARD.documents = data
    },
    setCustomReportCols(state,data){
      state.CUSTOM_REPORT_COLS = data
    },
    setUsedMaterialFilters(state,data){
      state.USER_MATERIAL_FILTERS = data
    },
    setTableFilters(state,data){
      state.TABLE_FILTERS.push(data)
    },
    rewriteExistingFilter(state,data){
      state.TABLE_FILTERS[data[0]].filters[data[1]] = data[2]
    },
    appendNewColFilter(state,data){
      state.TABLE_FILTERS[data[0]].filters.push(data[1])
    },
    removeColFilter(state,data){
      state.TABLE_FILTERS[data[0]].filters.splice(data[1], 1)
    },
    setSystemConfigs(state,data){
      state.SYSTEM_CONFIGS = [...data]
    },
    setHomeCategroyDRange(state,data){
      state.HOME_CATEGORY_DRANGE = data
    },
    setFormChanged(state,data){
      state.ANY_FORM_STATE_CHANGED = data
    }
  },
  actions: {
    HTTP_POST_REQUEST({commit,state},data)
    {
      return axios
      .post(
        data.url,
        data.payload
      )
      .then(response => {
        return response
      })
    },
    HTTP_PUT_REQUEST({commit,state},data)
    {
      return axios
      .put(
        data.url,
        data.payload
      )
      .then(response => {
        return response
      })
    },
    HTTP_GET_REQUEST({commit,state},url)
    {
      return axios
        .get(url)
        .then(response => { return response })
    },
    HTTP_DELETE_REQUEST({commit,state},url)
    {
      return axios
        .delete(url)
        .then(response => { return response })
    }
  },
  modules: {
    
  },
  plugins: [vuexLocal.plugin]
})
