export default{
  methods:{
    /**
    * @Description
    * 
    * 1. This method is used to apply the filters on column
    * 2. If first get the applied filters and loop through all filters
    * 3. Then check for each filter type data and keep storing filtered data in array
    * 4. In the end assign this filterd data to filterd_data array variable which is rendering the table listing
    * 
    * @param table { String}
    * @return void
    **/
    handleFilterEvent(table){

      table        = table ? table.toLowerCase() : table
      let data     = this.items
      let filtered = data

      this.getFilters(table)

      this.applied_filters.forEach(filters=>{

        if(filters.rule_type == 'Contains'){
          filtered = filtered.filter(d=> {
            return d[filters.col_name] ?  d[filters.col_name].includes(filters.value) : false
          })
        }
        if(filters.rule_type == 'Not Contains'){
          filtered = filtered.filter(d=> {
            return d[filters.col_name] ? !d[filters.col_name].includes(filters.value)  : false
          })
        }
        if(filters.rule_type == 'Start With'){
          filtered = filtered.filter(d=> { return d[filters.col_name] ? d[filters.col_name].startsWith(filters.value) : false })
        }
        if(filters.rule_type == 'Ends With'){
          filtered = filtered.filter(d=> { return d[filters.col_name] ? d[filters.col_name].endsWith(filters.value) : false })
        }
        if(filters.rule_type == 'Equals'){
          filtered = filtered.filter(d=>d[filters.col_name] == filters.value)
        }
        if(filters.rule_type == 'Not Equals'){
          filtered = filtered.filter(d=>d[filters.col_name] != filters.value)
        }
        if(filters.rule_type == 'Date is'){
          filtered = filtered.filter(d=>{ return this.getParsedDate(d[filters.col_name],true) == filters.value })
        }
        if(filters.rule_type == 'Date is not'){
          filtered = filtered.filter(d=>{ return this.getParsedDate(d[filters.col_name],true) != filters.value })
        }
        if(filters.rule_type == 'Date is before'){
          filtered = filtered.filter(d=>{ 
            return new Date(this.getParsedDate(d[filters.col_name],true)) < new Date(filters.value )
          })
        }
        if(filters.rule_type == 'Date is after'){
          filtered = filtered.filter(d=>{ 
            return new Date(this.getParsedDate(d[filters.col_name],true)) > new Date(filters.value )
          })
        }

      })

      /*****************************************************************************************/
      /*           Check If show deleted values settings is off then hide deleted data         */
      /*****************************************************************************************/
      let showDeleted = this.$store.state.SYSTEM_CONFIGS.find(d=>d.show_deleted_values == true)
      if(!showDeleted){
        filtered = filtered.filter(d=>d.deleted == 0 || d.Deleted == 0)
      }
      /******************************************************************************************/

      this.filterd_data = filtered
    },
    /**
    * @Description
    * 
    * 1. This method is used to get the list of all Applied filters on table from store(vuex)
    * 2. The applied filters are stored each time in the local storage for later use
    * 3. If found any applied filter it returns that filters array, otherwise empty array
    * 
    * @param table { String }
    * @return void
    **/
    getFilters(table){
      table       = table ? table.toLowerCase() : table
      let filters = this.$store.state.TABLE_FILTERS.find(d=>d.table == table)
      
      if(filters && filters.filters.length > 0){
        this.applied_filters = [...filters.filters]
      }else if(filters && !filters.filters.length){
        this.applied_filters = []
      }
    },
    /**
    * @Description
    * 
    * 1. This method is used to remove any filter from table and it also removes from the store
    * 2. Once removed then rerender the table data to apply latest filter and print latest filters
    * 
    * @param col_index { Number }
    * @return void
    **/
    removeFilter(col_index,table){
      table         = table ? table.toLowerCase() : table
      let filters   = this.$store.state.TABLE_FILTERS.find(d=>d.table == table)
      let tbl_index = this.$store.state.TABLE_FILTERS.indexOf(filters)

      this.$store.commit('removeColFilter',[tbl_index,col_index])
      this.getFilters(table)
      this.handleFilterEvent(table)
    }
  }
}