import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import mixin from '../mixins/GeneralMixin.js'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta : {
      requiresAuth : true,
      permission   : 'view-dashboard'
    }
  },
  {
    path: '/jobs',
    component: () => import('../views/Jobs'),
    meta : {
      requiresAuth : true,
      permission   : 'view-jobs'
    },
    children : [
      {
        path: '/',
        component: () => import('../components/jobs/index.vue'),
        meta : {
          requiresAuth : true,
          permission   : 'view-jobs'
        }
      },
      {
        path: 'detail/:id',
        name : 'JobDetails',
        component: () => import('../views/JobDetails'),
         meta : {
          requiresAuth : true,
          permission   : 'view-jobs'
        }
      },
    ]
  },
  {
    path: '/job-schedule',
    name: 'JobSchedule',
    component: () => import('../views/JobSchedule'),
    meta : {
      requiresAuth : true,
      permission   : 'view-job-schedules'
    }
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/Documents'),
    meta : {
      requiresAuth : true,
      permission   : 'view-all-documents'
    }
  },
  {
    path: '/all-job-pictures',
    name: 'AllJobPictures',
    component: () => import('../views/AllJobPictures'),
    meta : {
      requiresAuth : true,
      permission   : 'view-all-pictures'
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/Orders'),
    meta : {
      requiresAuth : true,
      permission   : 'view-orders-listing'
    }
  },
  {
    path: '/order-submission',
    name: 'OrderSubmission',
    component: () => import('../views/OrderSubmission'),
    meta : {
      requiresAuth : true,
      permission   : 'order-submissions'
    }
  },
  {
    path: '/order-submission/:id',
    name: 'OrderSubmissionSingle',
    component: () => import('../views/OrderSubmission'),
    meta : {
      requiresAuth : true,
      permission   : 'order-submissions'
    }
  },
  {
    path: '/materials',
    name: 'Materials',
    component: () => import('../views/Materials'),
    meta : {
      requiresAuth : true,
      permission   : 'view-materials'
    }
  },
  {
    path: '/inventory-submission',
    name: 'InventorySubmission',
    component: () => import('../views/InventorySubmission'),
    meta : {
      requiresAuth : true,
      permission   : 'inventory-submission'
    }
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import('../views/Companies'),
    meta : {
      requiresAuth : true,
      permission   : 'view-companies'
    }
  },
  {
    path: '/vendors',
    name: 'Vendors',
    component: () => import('../views/Vendors'),
    meta : {
      requiresAuth : true,
      permission   : 'view-vendors'
    }
  },
  {
    path: '/companies/vendor/details/:id',
    name: 'VendorDetails',
    component: () => import('../components/vendors/VendorDetails'),
    meta : {
      requiresAuth : true,
      permission   : 'view-vendors'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users'),
    meta : {
      requiresAuth : true,
      permission   : 'view-users'
    }
  },
  {
    path: '/role-permissions',
    name: 'RolePermissions',
    component: () => import('../components/users/RolePermissions'),
    meta : {
      requiresAuth : true,
      permission   : 'assign-permissions'
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/Chat'),
    meta : {
      requiresAuth : true,
      /* PERMISSION PENDING*/
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts'),
    meta : {
      requiresAuth : true,
      permission   : 'view-contacts'
    }
  },
  {
    path: '/reminders',
    name: 'Reminders',
    component: () => import('../views/Reminders'),
    meta : {
      requiresAuth : true,
      permission   : 'view-reminders'
    }
  },
  {
    path: '/custom-reports',
    name: 'CustomReports',
    component: () => import('../views/CustomReports'),
    meta : {
      requiresAuth : true,
      permission   : 'view-custom-reports'
    }
  },
  {
    path: '/order-report',
    name: 'OrderReport',
    component: () => import('../views/OrderReport'),
    meta : {
      requiresAuth : true,
      permission   : 'view-order-reports'
    }
  },
  {
    path: '/upcoming-jobs',
    name: 'UpcomingJobsReport',
    component: () => import('../views/UpcomingJobsReport'),
    meta : {
      requiresAuth : true,
      permission   : 'view-upcoming-job-reports'
    }
  },
  {
    path: '/used-materials',
    name: 'UsedMaterial',
    component: () => import('../views/UsedMaterial'),
    meta : {
      requiresAuth : true,
      permission   : 'view-used-materials'
    }
  },
  {
    path: '/insurance-policies',
    name: 'InsurancePolicies',
    component: () => import('../views/InsuranceReport'),
    meta : {
      requiresAuth : true,
      permission   : 'view-insurance-policies'
    }
  },
  {
    path: '/incidents',
    name: 'incidents',
    component: () => import('../views/Incidents.vue'),
    meta : {
      requiresAuth : true,
      permission   : 'view-incidents'
    }
  },
  {
    path: '/dictionaries',
    name: 'Dictionaries',
    component: () => import('../views/Dictionaries'),
    meta : {
      requiresAuth : true,
      permission   : 'view-dictionaries'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/crm-issues',
    name: 'CRMIssues',
    component: () => import('../views/CRMIssues.vue'),
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/temp',
    name: 'temp',
    component: () => import('../views/JobsSchedules.vue'),
    meta : {
      requiresAuth : true,
      permission   : 'view-job-schedules'
    }
  },
  {
    path: '/estimation',
    name: 'estimation',
    component: () => import('../views/Estimation.vue'),
    meta : {
      requiresAuth : true,
      permission   : 'view-estimations'
    }
  },
  {
    path: '/un-authorized',
    name: 'UnAuthorized',
    component: () => import('../views/UnAuthorized.vue'),
    meta : { }
  },
  { path: "*", component: () => import('../views/404.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.USER_LOGGED_IN) {
      next({ name: 'Login' }) // go to Login Page
    } else {
      //check if USER has permissions of the route
      // let hasPermission = mixin.methods.hasPermission(to.matched.map(d=>d.meta.permission)[0], store)
      // if (!hasPermission) {
        // next({ name : 'UnAuthorized' })
      // }else{
        next() // go to wherever I'm going
      // }
    }
  } else {
    next() // does not require auth, make sure to always call next()! //Login Page
  }
})


export default router
