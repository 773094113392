<template>
  <div>

    <v-container fluid>
      <v-row>

        <!-- Donut Chart For Job Statuses -->
        <v-col cols="12" md="3">
          <v-card outlined class="elevation-1">
            <v-skeleton-loader
              v-if="loading_top_bar"
              type="article, list-item"
            ></v-skeleton-loader>
            <donut-chart
              :labels="dchartLabels"
              :colors="dchartColors"
              :data-set="dchartData"
              v-else
            >
            </donut-chart>
          </v-card>
        </v-col>

        <!-- Total Customers -->
        <v-col cols="12" md="2">
          <v-card
            class="mx-auto pa-3 elevation-1"
            outlined
            height="180"
          >
            <v-skeleton-loader
              v-if="loading_top_bar"
              type="article"
            ></v-skeleton-loader>
            <v-list-item three-line v-else>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Total Customers
                </div>
                <v-list-item-title class="text-h3 mt-3 text-center mb-1 primary--text">
                  <v-icon color="primary" x-large>mdi-account-supervisor-circle</v-icon>
                  {{top_bar_stats.total_customers | padZeros | commas}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <!-- Days On Site -->
        <v-col cols="12" md="2">
          <v-card
              class="mx-auto pa-3 elevation-1"
              outlined
              height="180"
          >
            <v-row>
              <v-icon class="ml-auto mt-2 mr-2" color="primary" @click="openDialog">mdi-filter</v-icon>
            </v-row>
            <v-skeleton-loader
                v-if="loading_top_bar"
                type="article"
            ></v-skeleton-loader>
            <v-list-item  class="no-padding-item" three-line v-else>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Job Days On Site
                </div>
                <router-link to="/jobs" class="no-underline">
                <v-list-item-title class="text-h4 mt-5 text-center mb-1 primary--text">
                  <v-icon color="primary" large>mdi-clock</v-icon>
                  {{daysOnSite | padZeros | commas}}
                </v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-dialog v-model="onsiteDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>Select Job Statuses</v-card-title>
            <v-card-text>
              <v-select
                  v-model="jobStatusSelected"
                  :items="jobStatuses"
                  label="Select Status"
                  multiple
              ></v-select>
            </v-card-text>
            <v-card-text>
              <v-select
                  v-model="jobNameSelected"
                  :items="jobNames"
                  label="Select Job"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="closeDialog" text class="ml-auto">
                Close
              </v-btn>
              <v-btn @click="searchJobStatus" text color="primary">
                Search
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Violations Stats Section -->
        <v-col cols="12" md="3">
          <v-card
            class="mx-auto pa-3 elevation-1"
            outlined
            height="180"
          >
            <v-skeleton-loader
              v-if="loading_top_bar"
              type="article"
            ></v-skeleton-loader>
            <v-list-item three-line v-else :class="{'pl-0' : isMobile, 'pr-0' : isMobile}">
              <v-list-item-content>
                <div class="text-overline mb-4">Violations</div>
                <v-list-item-title class="text-overline mb-1 ">
                  <v-icon color="red">mdi-sign-caution</v-icon>
                  No. of Violations:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{top_bar_stats.total_violations | padZeros | commas}}
                </v-list-item-title>
                <v-list-item-title class="text-overline mb-1 ">
                  <v-icon color="red">mdi-cash-100</v-icon>
                  Penalties Amount:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{top_bar_stats.panalties_amount | padZeros | commas}}
                </v-list-item-title>
                <v-list-item-title class="text-overline mb-1 ">
                  <v-icon color="deep-orange">mdi-briefcase-edit</v-icon>
                  Total Affected Jobs:&nbsp;
                  {{top_bar_stats.total_affected_jobs | padZeros | commas}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <!-- Users Stats Section -->
        <v-col cols="12" md="2">
          <v-card
            class="mx-auto pa-3 elevation-1"
            outlined
            height="180"
            >
            <v-skeleton-loader
              v-if="loading_top_bar"
              type="article"
            ></v-skeleton-loader>
            <v-list-item three-line v-else>
              <v-list-item-content>
                <div class="text-overline mb-4">
                Users/Employees
                </div>
                <v-list-item-title class="text-overline mb-1 ">
                  <v-icon color="primary">mdi-account-multiple</v-icon>
                  Total :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{top_bar_stats.total_users | padZeros | commas}}
                </v-list-item-title>
                <v-list-item-title class="text-overline mb-1 ">
                  <v-icon color="green">mdi-account-multiple-check</v-icon>
                  Active :&nbsp;&nbsp;&nbsp;&nbsp; {{top_bar_stats.active_users | padZeros | commas}}
                </v-list-item-title>
                <v-list-item-title class="text-overline mb-1 ">
                  <v-icon color="orange">mdi-account-switch</v-icon>
                  Managers: {{top_bar_stats.total_managers | padZeros | commas}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

      </v-row>

      <!-- BOTTOM ROW -->
      <v-row>
        <v-col cols="12" md="8">
          <v-card outlined class="elevation-1">
            <v-tabs v-model="tab" :background-color="greyThemeColor" show-arrows>
              <v-tab key="tab-1">
                JOB TYPES (MOST USED TYPE WISE)
              </v-tab>
              <v-tab key="tab-2">
                RECENT JOBS
              </v-tab>
              <v-spacer></v-spacer>

              <!-- Show selected Date filter -->
              <v-chip
                class="mt-2 mr-2 py-0"
                close
                color="primary"
                outlined
                label
                text-color="primary"
                height="1.7rem"
                @click:close="removeRange"
                v-if="tab == 0 && $store.state.HOME_CATEGORY_DRANGE && $store.state.HOME_CATEGORY_DRANGE.start"
              >
                {{$store.state.HOME_CATEGORY_DRANGE.start}} ~ {{$store.state.HOME_CATEGORY_DRANGE.end}}
              </v-chip>
              <!-- Date Range Filter -->
              <v-dialog
                ref="dialog"
                v-model="picker_dialog"
                :return-value.sync="date_range"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small class="mt-2 mr-2 elevation-1" v-bind="attrs" v-on="on" v-show="tab == 0">
                    <v-icon color="primary">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker range v-model="date_range" scrollable >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="picker_dialog = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="pickerClosed">OK</v-btn>
                </v-date-picker>
              </v-dialog>

            </v-tabs>



            <v-tabs-items v-model="tab">

              <!-- BAR CHAR OF JOB TYPE WISE -->
              <v-tab-item key="tab-1">
                <v-card flat height="300" class="overflow-x-auto">
                  <v-skeleton-loader
                    v-if="loading"
                    type="table-tbody"
                    height="250"
                  ></v-skeleton-loader>
                  <bar-chart
                    :height="290"
                    :width="300"
                    :labels="bchartLabels"
                    :data-set="bchartData"
                    title="Total Jobs"
                    v-else-if="bchartData.length > 0"
                  >
                  </bar-chart>
                </v-card>
              </v-tab-item>

              <!-- RECENT JOBS TABLE -->
              <v-tab-item key="tab-2">
                <v-card flat height="300">
                  <v-skeleton-loader
                    v-if="loading"
                    type="table-tbody"
                    height="250"
                  ></v-skeleton-loader>
                  <v-simple-table  height="280" dense fixed-header v-else>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">ID</th>
                          <th class="text-left">Job</th>
                          <th class="text-left">Date</th>
                          <th class="text-left" v-if="!isMobile">Status</th>
                          <th class="text-center">Today Report Sent?</th>
                          <th class="text-center">Today Pictures Sent?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in recent_jobs" :key="item.id">
                          <td>
                            <a :href="`/jobs/detail/${item.id}`" target="_blank" class="no-underline">
                              {{ item.id }}
                            </a>
                          </td>
                          <td>{{ item.name }}</td>
                          <td v-if="!isMobile">{{ item.date }}</td>
                          <td v-if="!isMobile">{{ item.status }}</td>
                          <td class="text-center">
                            <v-icon v-if="item.report_sent == 1" color="green">mdi-check-outline</v-icon>
                            <v-icon v-else-if="item.report_sent == 0" color="red">mdi-close-outline</v-icon>
                            <span v-else>N/A</span>
                          </td>
                          <td class="text-center">
                            <v-icon v-if="item.picture_sent == 1" color="green">mdi-check-outline</v-icon>
                            <v-icon v-else-if="item.picture_sent == 0" color="red">mdi-close-outline</v-icon>
                            <span v-else>N/A</span>
                          </td>
                        </tr>
                        <v-divider></v-divider>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>

        <!-- JOB SCHEDULES -->
        <v-col cols="12" md="4">
          <job-schedule></job-schedule>
        </v-col>

      </v-row>
    </v-container>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
    'donut-chart': () => import('@/components/charts/Donut'),
    'bar-chart': () => import('@/components/charts/Bar'),
    'job-schedule': () => import('@/components/home/JobSchedules')
  },
  data: () => ({
    tab             : 0,
    loading         : false,
    loading_top_bar : false,
    picker_dialog   : false,
    date_range      : [],
    recent_jobs     : [],
    jobNames        : [],
    top_bar_stats: {
      total_jobs          : 0,
      closed_jobs         : 0,
      up_jobs             : 0,
      total_violations    : 0,
      down_jobs           : 0,
      declined_jobs       : 0,
      total_customers     : 0,
      panalties_amount    : 0,
      total_affected_jobs : 0,
      total_users         : 0,
      active_users        : 0,
      total_managers      : 0,
    },
    dchartLabels      : ['Up Jobs', 'Down Jobs', 'Closed Jobs', 'Declined Jobs'],
    dchartColors      : ['#ff9800', '#f44336', '#4caf50', '#872019'],
    dchartData        : [],
    bchartLabels      : [],
    bchartData        : [],
    daysOnSite        : 0,
    jobStatuses       : [],
    onsiteDialog      : false,
    jobStatusSelected : [],
    jobNameSelected    : ''
  }),
  mounted() {
    this.getTopBarStats()
    this.getRecentJobs()
    this.getJobTypeWise()
    this.getDaysOnSite()
    this.getJobStatusesData()
    this.getJobsData()
  },
  methods: {
    /**
    * @Description
    *
    * 1. This method is used to get the top row status
    * 2. gets the stats of top row in dashboard and set in relevant variables to display the stats
    *
    * @params none
    * @return void
    **/
    getTopBarStats() {
      this.loading_top_bar = true
      this.$store.dispatch('HTTP_GET_REQUEST', `/dashboard/top-bar-stats/${this.$store.state.USER_DATA.user['ID']}`).then(response => {
        if (response.data) {
          this.top_bar_stats = response.data
          this.dchartData.push(response.data.up_jobs)
          this.dchartData.push(response.data.down_jobs)
          this.dchartData.push(response.data.closed_jobs)
          this.dchartData.push(response.data.declined_jobs)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(f => {
        this.loading_top_bar = false
      })
    },
    /**
    * @Description
    *
    * 1. This method is used to get the recent jobs stats, it gets the 10 most recent jobs from API
    * 2. It get the data from API and display in recent jobs tables
    *
    * @params none
    * @return void
    **/
    getRecentJobs() {
      this.loading = true
      this.$store.dispatch('HTTP_GET_REQUEST', `/dashboard/recent-jobs/${this.$store.state.USER_DATA.user['ID']}`).then(response => {
        if (response.data) {
          this.recent_jobs = response.data
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(f => {
        this.loading = false
      })
    },
    /**
    * @Description
    *
    * 1. This method is used to get the data for bar chart of jobs type wise
    * 2. Gets the data and then map the types as charts (x-axis) label and jobs as chart (y-axis) data
    * 3. In order to display the data we need to make 2 arrays; one for labels and the other one for the values (number)
    *
    * @params none
    * @return void
    **/
    getJobTypeWise(payload) {
      this.loading = true
      this.$store.dispatch('HTTP_POST_REQUEST', {url: `/dashboard/jobs-type-wise`, payload: payload}).then(response => {
        if (response.data) {
          this.bchartLabels = response.data.map(d => {
            return d.type
          })
          this.bchartData = response.data.map(d => {
            return d.jobs
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(f => {
        this.loading = false
      })
    },
    /**
    * @Description
    *
    * 1. This method is used get the selected date ranges from the category chart date range picker
    * 2. It gets the selected range and get min and max date using getMinMaxDate, as we can select from picker in either order
    * 3. Then calls getJobTypeWise method and passed the date ranges to get data from backend
    *
    * @params none
    * @return void
    **/
    pickerClosed() {
      this.$refs.dialog.save(this.date_range)

      let range = this.getMinMaxDate(this.date_range)
      this.getJobTypeWise(range)

      this.$store.commit('setHomeCategroyDRange', range)
    },
    /**
    * This method is used to cear date filter from category graph
    **/
    removeRange() {
      this.getJobTypeWise()
      this.$store.commit('setHomeCategroyDRange', {})
    },
    /**
    * @Description
    *
    * 1. This method is used to get the Days on site of all jobs
    *
    * @params none
    * @return void
    **/
    getDaysOnSite(job_status = null, job_name = null) {
      this.loading = true
      let url = '/dashboard/days-on-site';
      if (job_status || job_name) {
        url += '?';
        if (job_status) {
          url += `job_status=${encodeURIComponent(job_status)}`;
        }
        if (job_name) {
          url += `${job_status ? '&' : ''}job_name=${encodeURIComponent(job_name)}`;
        }
      }
      this.$store.dispatch('HTTP_GET_REQUEST', url).then(response => {
        if (response.data) {
          this.daysOnSite = response.data[0]['tally_marks']
          if (this.daysOnSite === null) {
            this.daysOnSite = 0
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(f => {
        this.loading = false
      })
    },
    getJobStatusesData() {
      this.loading = true
      this.$store.dispatch('HTTP_GET_REQUEST', `job-status`).then(response => {
        if (response.data) {
          this.jobStatuses = response.data.map(status => status.name)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(f => {
        this.loading = false
      })
    },
    openDialog() {
      this.onsiteDialog = true;
    },
    closeDialog() {
      this.onsiteDialog = false;
    },
    searchJobStatus() {
      this.getDaysOnSite(this.jobStatusSelected,this.jobNameSelected)
      this.closeDialog();
    },
    getJobsData() {
      this.loading = true
      this.$store.dispatch('HTTP_GET_REQUEST', `job-names`).then(response => {
        if (response.data) {
          this.jobNames = response.data.map(job => job.name)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(f => {
        this.loading = false
      })
    },
  }
};
</script>

<style scoped>
.no-underline{
  text-decoration : none;
}
.no-underline:hover{
  text-decoration : underline;
  font-weight     : bold;
}
.no-padding-item .v-list-item__content {
  padding-top: 0;
}
.no-underline {
  text-decoration: none;
}
.no-underline:hover {
  text-decoration: none;
}
</style>
