export default{
  /**
  * @Description
  *
  * 1. This computed prop is used to check if it's a mobile view or desktop
  * 2. Checks the windows width is less than 600, if it's mobile then it returns the true
  *
  * @return Boolean { true | false }
  **/
  computed:{
    isMobile(){
      return window.innerWidth < 600
    },
    greyThemeColor(){
      return this.$store.state.DARK_MODE ? '' : 'grey lighten-3'
    },
    greyThemeColor1(){
      return this.$store.state.DARK_MODE ? '' : 'grey lighten-4'
    },
    isDark(){
      return this.$store.state.DARK_MODE
    }
  },
  methods:{
    /*mulitselect clear chips selection*/
    remove(item,variable) {
      variable.splice(variable.indexOf(item), 1)
      variable = [...variable]
    },
    /**
    * @Description
    *
    * 1. This method is used to get the file exentsion from the file name
    * 2. It split the name on . and gets the last string as an exention
    *
    * @param fileName { String }
    * @return String
    **/
    getFileExtention(fileName, dbFileName = null){
      let extension =  fileName.lastIndexOf('.') > 0 ? fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase() : undefined;

      if (extension == undefined && dbFileName != null){
        extension = dbFileName.lastIndexOf('.') > 0 ? dbFileName.substr(dbFileName.lastIndexOf('.') + 1).toLowerCase() : undefined;
      }

      return extension
    },
    getNameWithoutExtention(fileName){
      return fileName.lastIndexOf('.') > 0 ? fileName.split('.')[0] : fileName;
    },
    /**
    * @Description
    *
    * 1. This method is used to get the file type either it's image, pdf or doc or xls
    * 2. It gets the file extension and checks if the exentsion is in any of img  then return IMG
    * 3. If extension is doc,docx then DOC and if pdf then PDF, if xls or xlsx then XLS
    *
    * @param fileName { String }
    * @return String
    **/
    getFileType(fileName, dbFileName=null){

      let result = undefined
      let ext    = this.getFileExtention(fileName, dbFileName)

      if (['jpeg', 'jpg', 'png', 'gif', 'tiff'].includes(ext) ) result = 'IMG'
      if (ext === 'pdf') result = 'PDF'
      if (['doc', 'docx'].includes(ext)) result = 'DOC'
      if (['xls', 'xlsx'].includes(ext)) result = 'XLS'

      return result
    },
    /**
    * @Description
    *
    * 1. This method is used to get the file ICON
    * 2. It gets the file name and return the relevant the icon name
    *
    * @param filename { String }
    * @return String
    **/
    getFileIcon(filename){
      let type = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : 'none'

      switch(type[0]){
        case 'pdf':
          return 'file-pdf-box'
        case 'png':
        case 'jpg':
        case 'jpeg' :
          return 'file-image'
        case 'doc':
          return 'text-box-outline'
        default:
          return 'file'
      }
    },
    /**
    * @Description
    *
    * 1. This method is used to get the parsed date in humar readable format
    * 2. It gets the d and parse it with format YYYY-MM-DD hh:mm:ss
    *
    * @param d { Date }
    * @return Date String
    **/
    getParsedDate(d,onlyDate=false){

      if(!d) { return }

      let a      = new Date(d)
      let year   = a.getUTCFullYear()
      let month  = ("0" + (a.getUTCMonth()+1)).slice(-2)
      let date   = ("0" + a.getUTCDate()).slice(-2)
      let hour   = ("0" + a.getHours()).slice(-2)
      let min    = ("0" + a.getMinutes()).slice(-2)
      let sec    = ("0" + a.getSeconds()).slice(-2)
      let time   = `${month}/${date}/${year} ${hour}:${min}:${sec}`

      if (onlyDate){
        time = `${month}/${date}/${year}`
      }

      return time
    },
    /**
    * @Description
    *
    * 1. This method is used to get the Date 1 year later than provided one
    * 2. It gets the date and convert it to the new Date object
    * 3. Then adds the 1 year into it and returns the parsed Date using getParsedDate method
    *
    * @param date { Date String }
    * @return void
    **/
    get1YrLaterDate(date){
      if (!date) { return }
      let later = new Date(date)
      return this.getParsedDate(later.setUTCFullYear(later.getUTCFullYear() + 1), true)
    },
    formatDate (date){
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    formatDatesArray(dates){
      if(!dates || !dates.length) return []

      let response = []
      dates.forEach(d=>{
        response.push(this.formatDate(d))
      })

      return response
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    parseDateArray(dates){
      if(!dates || !dates.length) return []

      let response = []
      dates.forEach(d=>{
        response.push(this.parseDate(d))
      })

      return response
    },
    getDatesDiffDays(first,second){
      const diffInMs   = new Date(second) - new Date(first)
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

      return diffInDays
    },
    /**
    * @Description
    *
    * 1. This method is used to get the min and max date from an array
    * 2. It checks the mas date by reduce function and sets by default algorithm of finding max value
    * 3. Then it finds the min value and sets the response object
    *
    * @param dates { String }
    * @return Object
    **/
    getMinMaxDate(dates){
      let max_date = dates.reduce(function (a, b) { return a > b ? a : b; })
      let min_date = dates.reduce(function (a, b) { return a < b ? a : b; })

      return {'start' : min_date, 'end' : max_date}
    },
    /**
    * @Description
    *
    * 1. Thid method is used to get the Status badge background colors
    * 2. It gets the status as param and applies switch statement & returns the relevant color
    *
    * @param status { String }
    * @return String
    **/
    getJobStatusColor (status) {
      switch(status){
        case 'Open':
          return 'orange'
        case 'Up':
          return 'deep-purple'
        case 'Closed':
          return 'green'
        case 'Needs Signoff':
          return 'blue'
        case 'Waiting for permit':
          return 'purple'
        case 'Proposal signed':
          return 'blue-grey'
        default :
          return 'primary'
      }
    },
    /**
    * This method is used to get the 10 characters long random string
    **/
    randomString(){
      return (Math.random() + 1).toString(36).substring(10)
    },

    /**
    * This methos is used to get random color names from defined colors array
    **/
    getRandColor(){
      const COLORS = ['primary', 'lime darken-3', 'pink', 'green darken-1', 'purple darken-1']
      return COLORS[Math.floor(Math.random() * 4)]
    },

    /**
    * @Descrtion
    *
    * 1. This is global method to check if the provided permission is present in the user's stored permission
    * 2. Gets the all permissions list from store and checks if the provided information is present or not
    * 3. If gets the store parameter, because methong is being called from router file as well
    *
    * @param permission { String }
    * @param store { Object-Vuex }
    * @return Boolean true|false
    **/
    hasPermission(permission, store = this.$store){

      if (!permission) { return }

      let response        = false
      let rolePermissions = store.state.USER_DATA.user.role_permissions

      if(rolePermissions){
        rolePermissions = rolePermissions.split(',')
        response        = rolePermissions.includes(permission)
      }

      return response
    },

    logout(){
      localStorage.clear()
      window.location.href= "/"
    }

  },
  filters:{
    /**
    * @Description
    *
    * 1. This filter is used to put the commas after 3 digits
    **/
    commas(val) {
      if(val === null || val === undefined) {
        return
      }
      if (val === 0) {
        return '000'
      }
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    /**
    * @Description
    *
    * 1. This filter is used to captalize the first letter of word
    **/
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    /**
    * @Description
    *
    * 1. This filter is used to put the leading 3 zeros for a number
    **/
    padZeros(num){
      if (!num) return null
      const zeroPad = (num, places) => String(num).padStart(places, '0')
      return zeroPad(num, 3)
    },
    /**
    * @Description
    *
    * 1. This filter is used to parse the string in human readable, means
    * 2. It removed the under scores from string and catalize the first letter of each word
    *
    * @param str { String }
    * @return String
    **/
    humanize(str){
      if(!str) {return}

      let i, frags = str.split('_')
      for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
      }
      return frags.join(' ')
    }
  }
}
